*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body{
  width: 100%;

}


.bg-theme{
  background-color: #FDF1ED;

}

.text-decoration-none{
  text-decoration: none;

}
a{
  text-decoration: none;
}


.underline{
  height: 3px ;
  width: 100px;
  background-color: orangered;
  border-radius: 2px;

}
.fit-content{
  height: fit-content;

}
/*color */
.color-white{
  color: white !important;
}
.color-dark{
  color: rgb(0, 0, 0);
}
.color-grey{
  color: rgb(62, 61, 61);
}
.color-light-grey{
  color: rgb(91, 90, 90);
}
.color-light-grey-xx{
  color: rgb(142, 140, 140);
}
.color-crimson{
  color: crimson;
}
.color-aqua{
  color: aqua;
}
.color-blue{
 color: rgb(102, 0, 255);
}
.color-pink{
  color: rgb(255, 0, 119);
}
.color-orange-red{
  color:orangered !important;
}

/* background-color */
.bg-transparent{
  background-color:transparent;
}
.bg-light-orange-red{
  background-color: rgb(250, 232, 225);
}
.bg-light-orange-redd{
  background-color: rgb(254, 235, 229);
}
.bg-white{
  background-color: white;
}
.bg-dark{
  background-color: rgb(0, 0, 0);
}
.bg-grey{
  background-color: grey;
}
.bg-crimson{
  background-color: crimson;
}
.bg-aqua{
  background-color: aqua;
}
.bg-blue{
  background-color: rgb(102, 0, 255);
}
.bg-pink{
  background:linear-gradient(90deg , rgb(255, 0, 119), orangered,rgb(255, 0, 119));
}
.bg-pink-1{
  background:linear-gradient(90deg , rgb(255, 0, 119), orangered);
}

.bg-orange-red{
  background-color:orangered !important;
}

.bg-orange{
  background-color:rgb(255, 124, 23);
}
.bg-red{
  background-color:red;
}
.bg-purple{
  background-color:purple;
}
.bg-yellow{
  background-color:yellow;
}
.bg-black{
  background-color:black;
}


.h-fit{
  height: fit-content;
}
.w-fit{
  width: fit-content;
}
/* height */
.h-auto{
  height: auto;
}
.h-s-1{
  height: .5em;
}
.h-1{
  height: 1em;
}
.h-m-2{
  height: 1.5em;
}
.h-2{
  height: 2em;
}

.h-m-3{
  height: 2.5em;
}
.h-3{
  height: 3em;
}

.h-m-4{
  height: 3.5em;
}
.h-4{
  height: 4em;
}
.h-m-5{
  height: 4.5em;
}
.h-5{
  height: 5em;
}

.h-6{
  height: 6em;
}
.h-10{
  height: 10vh;
}
.h-20{
  height: 20vh;
}
.h-30{
  height: 30vh;
}
.h-35{
  height: 35vh;
}

.h-40{
  height: 40vh;
}
.h-50{
  height: 50vh;
}
.h-55{
  height: 55vh;

}

.h-60{
  height: 60vh;
}
.h-70{
  height: 70vh;
}
.h-80{
  height: 80vh;
}
.h-90{
  height: 90vh;
}
.h-100{
  height: 100vh;
}
.h-200{
  height: 200vh;
}
.h-100-min{
  min-height: 100vh;
}
.h-100-per{
  height: 100%;
}
.h-50-per{
  height: 50%;
}
.h-40-per{
  height: 40%;
}
.h-60-per{
  height: 60%;
}




/* width */
.w-auto{
  width: auto;
}
.w-s-1{
  width: .5em;
}
.w-1{
  width: 1em;
}
.w-m-2{
  width: 1.5em;
}
.w-2{
  width: 2em;
}

.w-m-3{
  width: 2.5em;
}
.w-3{
  width: 3em;
}

.w-m-4{
  width: 3.5em;
}
.w-4{
  width: 4em;
}
.w-m-5{
  width: 4.5em;
}
.w-5{
  width: 5em;
}
.w-6{
  width: 6em;
}
.w-7{
  width: 7em;
}
.w-min-17{
  max-width: 17em;
}
.w-min-18{
  max-width: 18.8em;

}
.w-200px{
  width:200px;
}
.w-8{
  width: 8%;
}
.w-10{
  width: 10%;
}
.w-18{
  width: 18%;
}
.w-20{
  width: 20%;
}
.w-25{
  width: 25%;
}
.w-30{
  width: 30%;
}
.w-40{
  width: 40%;
}
.w-50{
  width: 50%;
}
.w-60{
  width: 60%;
}
.w-70{
  width: 70%;
}
.w-80{
  width: 80%;
}
.w-90{
  width: 90%;
}
.w-100{
  width: 100%;
}


.cr-pointer{
  cursor: pointer;
}



.cardnormal{
  width: 260px;
}












.rc-slider-rail {
background-color: rgb(252, 198, 178) !important;
}
.rc-slider-track{
background-color: orangered !important;
}
.rc-slider-handle{
  background-color: orangered !important;
  border: none !important;
  cursor: pointer !important;

}
.rc-slider-handle:active {
  border-color: orangered !important;
  box-shadow: 0 0 5px orangered !important;
 

}

.css-13cymwt-control {
background-color: transparent !important;

}
.css-7elnvt-control:hover{
  border-color: orangered !important;
  box-shadow: none;
  }
  .css-2gp94j-control:hover{
    border-color: orangered !important;
  
    }



   .custom-arrow {
      font-size: 30px;
    color: orangered !important;

      border: 1px solid orangered;
      cursor: pointer;
      height: 35px;
      width: 35px;
      top: 40%;
     
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }


    
 .custom-prev-arrow {
     position: absolute;
      left: -35px!important;
    }
    
  .custom-next-arrow {
      position: absolute;
      right: -35px!important;
    }



    .shadow-none{
      box-shadow: none;
    }

    .shadow-theme-1{
      box-shadow:0px 0px 20px rgb(250, 210, 197) !important;
    }